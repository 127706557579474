/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useReducer } from 'react';
import styled from 'styled-components';
import { motion, AnimateSharedLayout } from 'framer-motion';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import getStripe from '../../utils/stripejs';
import BigButton from '../atoms/bigButton';
import Collapse from '../molecules/collapse';
import Arrow from '../../assets/images/arrow-desktop.png';
import { submitPrice } from '../atoms/prices';
import { media } from '../../utils/media-queries';
import { Toggle, DonateBtn } from '../atoms/toggle';

const prices = {
  once: [
    ['DKK 5850', '€ 756', '$ 899'], // option1
    ['DKK 488', '€ 63', '$ 75'], // option2
    ['DKK 122', '€ 16', '$ 19'], // option3
  ],
  monthly: [
    ['DKK 488', '€ 63', '$ 75'], // option1
    ['DKK 244', '€ 33', '$ 38'], // option2
    ['DKK 122', '€ 16', '$ 19'], // option3
  ],
};

const initialState = {
  once: false,
  currency: 0,
  option: 2,
};

function reducer(state, action) {
  switch (action.type) {
    case 'chooseOnce':
      return { ...state, once: true };
    case 'chooseMonthly':
      return { ...state, once: false };
    case 'chooseKr':
      return { ...state, currency: 0 };
    case 'chooseEur':
      return { ...state, currency: 1 };
    case 'chooseDollar':
      return { ...state, currency: 2 };
    case 'chooseOption1':
      return { ...state, option: 1 };
    case 'chooseOption2':
      return { ...state, option: 2 };
    case 'chooseOption3':
      return { ...state, option: 3 };
    case 'chooseOptionChoose':
      return { ...state, option: 4 };
    default:
      throw new Error();
  }
}

const Donate = ({ copyOnce, copyMonthly, imageSrc, imageAlt, videoSrc }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [amount, setAmount] = useState();
  const [activeChooseCurr, setActiveChooseCurr] = useState(false);
  const [activePrice, setActivePrice] = useState(
    state.option !== 4 && prices.once[state.option - 1][state.currency]
  );
  useEffect(() => {
    if (state.option !== 4) {
      setActivePrice(
        state.once
          ? prices.once[state.option - 1][state.currency]
          : prices.monthly[state.option - 1][state.currency]
      );
    }
  }, [state]);

  const curr = {
    kr: 'Danish Kroner (DKK)',
    eur: 'Euro (€)',
    dollar: 'US Dollars ($)',
  };

  const displayActiveCurr = () => {
    if (activeChooseCurr) return 'Choose your currency';
    if (state.currency === 0) return curr.kr;
    if (state.currency === 1) return curr.eur;
    if (state.currency === 2) return curr.dollar;
  };

  const price = submitPrice(state);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const stripe = await getStripe();
    await stripe.redirectToCheckout({
      mode: state.once ? 'payment' : 'subscription',
      lineItems: [{ price, quantity: 1 }],
      successUrl: `${window.location.origin}/donate-success`,
      cancelUrl: `${window.location.origin}/donate`,
    });
  };

  const handleVariableAmount = async (e) => {
    e.preventDefault();

    const stripe = await getStripe();

    fetch('/.netlify/functions/create-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        amount,
        currency:
          (state.currency === 0 && 'DKK') ||
          (state.currency === 1 && 'EUR') ||
          (state.currency === 2 && 'USD'),
        mode: state.once ? 'payment' : 'subscription',
      }),
    })
      .then((response) => response.json())
      .then((session) => {
        stripe.redirectToCheckout({ sessionId: session.id });
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    const getIPAddress = async () => {
      const response = await fetch('https://get.geojs.io/v1/ip/geo.json');
      const data = await response.json();
      const country = data.country_code;
      const continent = data.continent_code;

      if (country === 'DK') {
        dispatch({ type: 'chooseKr' });
      } else if (continent !== 'EU') {
        dispatch({ type: 'chooseDollar' });
      } else {
        dispatch({ type: 'chooseEur' });
      }
    };

    getIPAddress().catch((error) => console.error(error));
  }, []);

  return (
    <StyledDonate className="grid">
      <div className="image-mobile">
        {imageSrc ? (
          <Img fluid={imageSrc} alt={imageAlt} />
        ) : (
          <video src={videoSrc} autoPlay loop muted playsInline />
        )}
        <Img fluid={imageSrc} alt={imageAlt} />
      </div>
      <div className="text">
        <AnimateSharedLayout>
          <StyledToggle>
            <div className="wrapper">
              <span className="bg" />
              <Toggle
                className={`donation ${state.once ? 'active-donation' : ''}`}
                color={state.once ? 'white' : 'var(--blue)'}
                onClick={() => dispatch({ type: 'chooseOnce' })}
                isSelected={state.once}
                text="Give once"
              />
              <Toggle
                className={`donation ${!state.once ? 'active-donation' : ''}`}
                color={!state.once ? 'white' : 'var(--blue)'}
                onClick={() => dispatch({ type: 'chooseMonthly' })}
                isSelected={!state.once}
                text="Give monthly"
              />
            </div>
          </StyledToggle>
        </AnimateSharedLayout>
        <div className="curr">
          <button
            style={{ color: activeChooseCurr && 'rgba(0,0,0,0.3)' }}
            type="button"
            onClick={() => setActiveChooseCurr(!activeChooseCurr)}
          >
            <span>
              <motion.img
                src={Arrow}
                alt="Small arrow"
                initial={{ rotate: 0 }}
                animate={{ rotate: activeChooseCurr ? 180 : 0 }}
                transition={{ duration: 0.2 }}
              />
            </span>
            {displayActiveCurr()}
          </button>
          <Collapse active={activeChooseCurr}>
            <li
              style={{ color: state.currency === 1 && 'var(--red)' }}
              onClick={() => {
                dispatch({ type: 'chooseEur' });
                setActiveChooseCurr(false);
              }}
            >
              {curr.eur}
            </li>
            <li
              style={{ color: state.currency === 2 && 'var(--red)' }}
              onClick={() => {
                dispatch({ type: 'chooseDollar' });
                setActiveChooseCurr(false);
              }}
            >
              {curr.dollar}
            </li>
            <li
              style={{ color: state.currency === 0 && 'var(--red)' }}
              onClick={() => {
                dispatch({ type: 'chooseKr' });
                setActiveChooseCurr(false);
              }}
            >
              {curr.kr}
            </li>
          </Collapse>
        </div>
        <div className="prices">
          <AnimateSharedLayout>
            <DonateBtn
              className={`price ${state.option === 1 ? 'active-price' : ''}`}
              isSelected={state.option === 1}
              onClick={() => dispatch({ type: 'chooseOption1' })}
              text={
                state.once
                  ? prices.once[0][state.currency]
                  : prices.monthly[0][state.currency]
              }
            />
            <DonateBtn
              className={`price ${state.option === 2 ? 'active-price' : ''}`}
              isSelected={state.option === 2}
              onClick={() => dispatch({ type: 'chooseOption2' })}
              text={
                state.once
                  ? prices.once[1][state.currency]
                  : prices.monthly[1][state.currency]
              }
            />
            <DonateBtn
              className={`price ${state.option === 3 ? 'active-price' : ''}`}
              isSelected={state.option === 3}
              onClick={() => dispatch({ type: 'chooseOption3' })}
              text={
                state.once
                  ? prices.once[2][state.currency]
                  : prices.monthly[2][state.currency]
              }
            />
            <DonateBtn
              className={`price ${state.option === 4 ? 'active-price' : ''}`}
              isSelected={state.option === 4}
              onClick={() => dispatch({ type: 'chooseOptionChoose' })}
              text="Other amount"
            />
          </AnimateSharedLayout>
        </div>
        {state.option !== 4 ? (
          <h3 className="bold-small">{`A ${activePrice} ${
            state.once
              ? (state.option === 1 && copyOnce.option1) ||
                (state.option === 2 && copyOnce.option2) ||
                (state.option === 3 && copyOnce.option3)
              : (state.option === 1 && copyMonthly.option1) ||
                (state.option === 2 && copyMonthly.option2) ||
                (state.option === 3 && copyMonthly.option3)
          }`}</h3>
        ) : (
          <div className="choose">
            <label htmlFor="amount">
              <input
                type="number"
                name="amount"
                min="1"
                placeholder="Enter amount"
                onChange={(e) => setAmount(e.target.value * 100)}
              />
              <p className="currency">
                {(state.currency === 0 && 'DKK') ||
                  (state.currency === 1 && '€') ||
                  (state.currency === 2 && '$')}
              </p>
            </label>
            {state.currency === 0 && amount / 100 < 3 && (
              <p className="number-error">
                The amount must be greater than or equal to 3.
              </p>
            )}
            {(state.currency === 1 || state.currency === 2) &&
              amount / 100 < 1 && (
                <p className="number-error">
                  The amount must be greater than or equal to 1.
                </p>
              )}
            <p className="thankyou">
              Please enter the amount you wish to donate. <br /> Every donation
              helps us. Thank you.
            </p>
          </div>
        )}
        <BigButton
          background="var(--red)"
          color="white"
          text="Go to payment"
          disabled={
            (state.currency === 0 && amount / 100 < 3) ||
            ((state.currency === 1 || state.currency === 2) && amount / 100 < 1)
          }
          onClick={state.option === 4 ? handleVariableAmount : handleSubmit}
        />
        <div className="process">
          <p style={{ color: 'var(--red)', opacity: 1 }}>1 Amount</p>
          <p>2 Payment</p>
          <p>3 Confirmation</p>
        </div>
      </div>
      <div className="image">
        {imageSrc ? (
          <Img fluid={imageSrc} alt={imageAlt} />
        ) : (
          <video src={videoSrc} autoPlay loop muted playsInline />
        )}
      </div>
    </StyledDonate>
  );
};

const StyledToggle = styled.div`
  font-size: var(--font-size-0);
  border-radius: 50px;
  color: var(--blue);
  display: inline-block;
  width: auto;
  margin-bottom: var(--spacing-S);

  .wrapper {
    position: relative;
  }

  button {
    padding: var(--spacing-XXS) var(--spacing-S);
  }

  .donation {
    position: relative;
  }

  .active-donation {
    color: white;
  }

  .active-toggle {
    border-radius: 50px;
    background: var(--blue);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    /* visibility: visible; */
    z-index: -1;
  }

  .bg {
    position: absolute;
    left: 0;
    background: #f2f2f2;
    width: 100%;
    height: 100%;
    display: inline-block;
    z-index: -2;
    border-radius: 50px;
  }

  @media ${media.L} {
    margin-bottom: var(--spacing-XL);
  }
`;

const StyledDonate = styled.section`
  margin-bottom: var(--v-spacing-L);

  .text {
    padding-top: var(--spacing-XXS);

    .curr {
      color: var(--blue);
      margin-bottom: var(--spacing-XS);

      button {
        font-size: var(--font-size-0);
      }

      span {
        padding-right: var(--spacing-XXXS);
        display: inline-block;
        transform: translateY(-4px);
      }

      ul {
        padding-left: var(--spacing-XXS);
        margin-top: var(--spacing-XS);

        li {
          padding-bottom: var(--spacing-XS);
        }
      }
    }

    .bold-small {
      font-family: var(--font-1);
      font-size: var(--font-size-1);
      max-width: 80%;
    }

    .prices {
      display: flex;
      color: var(--blue);
      margin-bottom: var(--spacing-XXS);
      font-size: var(--font-size-2);
      flex-direction: column;
      align-items: flex-start;

      button {
        padding: var(--spacing-XXXS) var(--spacing-XXS);
        margin-right: var(--spacing-XS);
        display: inline;
        position: relative;
      }

      .active-price {
        color: var(--red);
        border-radius: 50px;
      }

      .donate-btn {
        border-radius: 50px;
        border: 2px solid var(--red);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        visibility: visible;
        z-index: -1;
      }
    }

    .process {
      display: flex;
      font-size: var(--font-size-0);
      padding-top: var(--spacing-S);

      p {
        padding-right: var(--spacing-S);
        opacity: 30%;
      }
    }
  }

  .image-mobile {
    display: block;
    height: 50vw;

    video {
      object-position: 0 25%;
    }
  }

  .image {
    display: none;
  }

  .choose {
    .currency {
      position: absolute;
      color: var(--blue);
      right: 0;
      padding-right: var(--v-spacing-S);
      margin: auto;
    }

    label {
      font-size: var(--font-size-1);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60%;
      max-height: 100%;
    }

    input {
      width: 100%;
      height: 100%;
      text-align: left;
      padding: var(--v-spacing-S);
    }

    .number-error {
      font-size: var(--font-size-0);
      color: var(--red);
      padding-top: var(--spacing-XXS);
    }

    .thankyou {
      font-size: var(--font-size-0);
      color: var(--blue);
      padding-top: var(--spacing-XXS);
      padding-bottom: var(--v-spacing-M);
    }
  }

  @media ${media.L} {
    height: 90vh;

    .text {
      grid-column: 1 / 13;
      padding-top: 12vh;

      .curr {
        margin-bottom: var(--spacing-XXS);

        ul {
          padding-bottom: var(--spacing-M);

          li {
            padding-bottom: 0;
          }
        }
      }

      .prices {
        font-size: var(--font-size-1);
        flex-direction: row;
      }

      .thankyou {
        padding-bottom: var(--spacing-XL);
      }

      .bold-small {
        font-family: var(--font-2);
        font-size: var(--font-size-2);
        padding-bottom: var(--spacing-XL);
        max-width: 100%;
      }
    }

    .image {
      grid-column: 14 / 25;
      display: block;
    }

    .image-mobile {
      display: none;
    }

    .choose {
      label {
        max-height: 80%;
        height: 80%;
      }
    }
  }
`;

Donate.propTypes = {
  copyOnce: PropTypes.object,
  copyMonthly: PropTypes.object,
  imageSrc: PropTypes.object,
  videoSrc: PropTypes.string,
  imageAlt: PropTypes.string,
};

export default Donate;
