import { motion } from 'framer-motion';
import React from 'react';
import PropTypes from 'prop-types';

export const Toggle = ({
  isSelected,
  onClick,
  text,
  color,
  className,
  pointerEvents,
  opacity,
}) => (
  <button
    type="button"
    className={className}
    style={{ color, pointerEvents, opacity }}
    onClick={onClick}
  >
    {text}
    {isSelected && (
      <motion.div
        layoutId="active-toggle"
        className="active-toggle"
        transition={{ duration: 0.3 }}
      />
    )}
  </button>
);

Toggle.propTypes = {
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  pointerEvents: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  opacity: PropTypes.number,
};

export const DonateBtn = ({ isSelected, onClick, text, color, className }) => (
  <button
    type="button"
    className={className}
    style={{ color }}
    onClick={onClick}
  >
    {text}
    {isSelected && (
      <motion.div
        layoutId="donate-btn"
        className="donate-btn"
        transition={{ duration: 0.3 }}
      />
    )}
  </button>
);

DonateBtn.propTypes = {
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
};
