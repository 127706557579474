import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const BigButton = ({ text, onClick, disabled, background, color }) => (
  <StyledButton
    disabled={disabled}
    onClick={onClick}
    style={{ background, color }}
  >
    {text}
  </StyledButton>
);

const StyledButton = styled.button`
  border-radius: 50px;
  padding: var(--spacing-XXS) var(--spacing-S);

  :before {
    content: '→ ';
  }
`;

BigButton.propTypes = {
  text: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default BigButton;
