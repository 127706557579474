export const pricesStripeIDs = {
  option1once: {
    kr: 'price_1HyDh2G9p7ViK8jFzic2egpi',
    eur: 'price_1HyDh1G9p7ViK8jFodbIWD9o',
    dollar: 'price_1HyDh1G9p7ViK8jFsQaKdeEF',
  },
  option1monthly: {
    kr: 'price_1HyDh1G9p7ViK8jF19qG2z32',
    eur: 'price_1HyDh1G9p7ViK8jFAjcw28t8',
    dollar: 'price_1HyDh1G9p7ViK8jFeAYuTMgJ',
  },
  option2once: {
    kr: 'price_1HyDkCG9p7ViK8jFNX6rI2aZ',
    eur: 'price_1HyDkCG9p7ViK8jF8yUPfng5',
    dollar: 'price_1HyDkCG9p7ViK8jF3ofc62sm',
  },
  option2monthly: {
    kr: 'price_1HyDkCG9p7ViK8jFZSzIaxQ9',
    eur: 'price_1HyDkCG9p7ViK8jF5GHsNdRw',
    dollar: 'price_1HyDkCG9p7ViK8jFWUHDrocM',
  },
  option3once: {
    kr: 'price_1HyDqsG9p7ViK8jFa8a3wMz0',
    eur: 'price_1HyDqsG9p7ViK8jFyNEQV8cy',
    dollar: 'price_1HyDqsG9p7ViK8jFRx2irCGq',
  },
  option3monthly: {
    kr: 'price_1HyDqsG9p7ViK8jF5W3MzIQL',
    eur: 'price_1HyE64G9p7ViK8jFD172VZOI',
    dollar: 'price_1HyDqsG9p7ViK8jFlJoi7DeU',
  },
};

export const submitPrice = (state) => {
  if (state.once) {
    if (state.option === 1) {
      if (state.currency === 0) return pricesStripeIDs.option1once.kr;
      if (state.currency === 1) return pricesStripeIDs.option1once.eur;
      if (state.currency === 2) return pricesStripeIDs.option1once.dollar;
    }
    if (state.option === 2) {
      if (state.currency === 0) return pricesStripeIDs.option2once.kr;
      if (state.currency === 1) return pricesStripeIDs.option2once.eur;
      if (state.currency === 2) return pricesStripeIDs.option2once.dollar;
    }
    if (state.option === 3) {
      if (state.currency === 0) return pricesStripeIDs.option3once.kr;
      if (state.currency === 1) return pricesStripeIDs.option3once.eur;
      if (state.currency === 2) return pricesStripeIDs.option3once.dollar;
    }
  } else if (!state.once) {
    if (state.option === 1) {
      if (state.currency === 0) return pricesStripeIDs.option1monthly.kr;
      if (state.currency === 1) return pricesStripeIDs.option1monthly.eur;
      if (state.currency === 2) return pricesStripeIDs.option1monthly.dollar;
    }
    if (state.option === 2) {
      if (state.currency === 0) return pricesStripeIDs.option2monthly.kr;
      if (state.currency === 1) return pricesStripeIDs.option2monthly.eur;
      if (state.currency === 2) return pricesStripeIDs.option2monthly.dollar;
    }
    if (state.option === 3) {
      if (state.currency === 0) return pricesStripeIDs.option3monthly.kr;
      if (state.currency === 1) return pricesStripeIDs.option3monthly.eur;
      if (state.currency === 2) return pricesStripeIDs.option3monthly.dollar;
    }
  }
};
